/* General
========================================================================== */
@import "../variables/all-variables";
* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 175px;
    scrollbar-width: none;
    height: 100%;
}
::-webkit-scrollbar {
    display: none; /* For Chrome and Safari */
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: default;
    font-family: $font-primary;
    font-size: var(--text-font-size);
    line-height: var(--text-lineheight);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --font-body: $font-primary;

    --color-primary: var(--color-black-pearl);
    --color-secondary: var(--color-darkblue);

    --bg-primary: var(--color-black-pearl);
    --bg-secondary: var(--color-secondary);


    --color-success: var(--color-green);
    --color-danger: var(--color-red);
    --color-warning: var(--color-yellow);

    --text-color: var(--color-white);
    --text-font-size: map-get($text-sizes, base);
    --text-lineheight: 1.2;

    --bs-link-color-rgb: red;

}

// TODO: change to a utility class
.bg-primary {
    background-color: var(--bg-primary) !important;
}

.bg-dark-2 {
    background-color: var(--bg-secondary) !important;
}

// Order
@for $i from 1 through 3 {
    .order-#{$i} {
        @include media-breakpoint-up(md) {
            order: #{$i};
        }
    }

    .mobile-order-#{$i} {
        @include media-breakpoint-down(md) {
            order: #{$i};
        }
    }
}


.fade-in {
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

.modal-body {
    h5 {
      color: var(--color-white);
      font-weight: 500;
    }
    p {
        font-size: map-get($text-sizes, lg);
        font-weight: 400;
        line-height: 1.5;
    }
}

.data-spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: var(--color-secondary);
    height: 100%;
    align-items: center;

}