@import "../../styles/variables/variables";
@import "../../styles/vendor/bootstrap";

.typeWriter {

  &__text {
    font-size: map-get($text-sizes, 5xl);
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      font-size: map-get($text-sizes, 6xl);
    }
  }

  &__cursor {
    font-size: 1em;
    animation: blink 700ms step-end infinite;
  }

}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
