@import "../../styles/base/placeholders";

.hero {

  &__wrap {
    position: relative;
    z-index: 2;
  }

  &__mask {
    @extend %bg-absolute;
    z-index: 1;
  }

  &__bg {
    @extend %bg-image;
    @extend %bg-absolute;
    z-index: 0;
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__scrollBtn {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 32px;
    animation: bounce 2s ease infinite;
    width: 100%;
  }

  &__scrollBtn-wrapper {
    @extend %btn-secondary;
  }

  &__btn-bounce {
    animation: bounce 2s ease infinite;
    animation-delay: 300ms;
  }

}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
