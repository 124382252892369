@import "../../styles/variables/all-variables";
@import "../../styles/abstracts/helpers";
@import "../../styles/base/all-base";

.services {
  @extend %section-layout;

  &__featured-box {
      display: flex;
      gap: 32px;
  }

  &__featured-box-icon {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-shrink: 0;
    width: 70px;
    height: 70px;
  }

  &__featured-box-content {

  }

}