/* Fonts
========================================================================== */
/* stylelint-disable */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/Poppins-Thin.ttf') format('truetype');
}


@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/Poppins-Light.ttf') format('truetype');
}


@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/Poppins-Black.ttf') format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/Poppins-Bold.ttf') format('wotruetype');
}


$font-fallback: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue', sans-serif;
$font-primary: 'Poppins', $font-fallback;
