@import "src/styles/base/placeholders";
@import "src/styles/variables/font-variables";
@import "src/styles/variables/variables";

.input {
  background: var(--color-dark-3);
  border-color: var(--color-dark-3);
  color: var(--color-white);

  &:focus {
    background: #232a31;
    color: #fff;
  }
  &::placeholder {
    color: var(--color-gray);
  }
}
