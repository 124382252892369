@import "../../styles/base/placeholders";
@import "../../styles/variables/font-variables";
@import "../../styles/variables/variables";
@import "../../styles/vendor/bootstrap";

.portfolioItem {
    #{block}: &;

    position: relative;
    overflow: hidden;
    border-radius: 8px;
    height: 100%;

    &:hover {
      &:before {
        opacity: 1;
        transition: 200ms all ease;
        border-radius: 8px;
      }
      .portfolioItem__image {
        filter: blur(4px);
        transform: scale(1.03);
        transition: 200ms all ease;
        border-radius: 8px;
      }
      .portfolioItem__details {
        opacity: 1;
        transition: 200ms all ease;
      }

    }

    &:before {
      content: "";
      display: block;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(black, 40%);
      z-index: 2;
      transition: 200ms all ease;
    }

    &__image {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 8px;
      object-fit: cover;
      object-position: top center;
      filter: blur(0);
      position: relative;
      z-index: 1;
      transition: 200ms all ease;
    }

  &__details {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    opacity: 0;
    z-index: 3;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 8px;
    transition: 200ms all ease;
    color: var(--color-white);

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 0;
    }

    h2 {
      color: var(--color-white);

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 0;
        font-size: map-get($text-sizes, base);
      }
    }

  }
}