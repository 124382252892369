@import "../../styles/variables/all-variables";
@import "../../styles/abstracts/helpers";

.about {

  &__my-info {
    display: flex;
    flex-direction: column;
    ul {
      li {
        border-bottom: 1px solid hsla(0,0%,98%,.12);
        padding-bottom: 12px;
        padding-top: 12px;
      }
    }
  }

  &__info {
    margin-right: 8px;
  }

  &__brands-grid {

  }

  &__brands-grid-item {
    h4 {
      font-size: map-get($text-sizes, 5xl);
    }

  }

}