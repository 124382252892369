@import "../../styles/abstracts/helpers";
@import "../../styles/base/all-base";
@import "../../styles/vendor/bootstrap";

.contact {
  @extend %section-layout;

  &__social {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @include media-breakpoint-up(lg) {
      align-items: flex-start;
    }
  }
}