@import "../../styles/base/placeholders";
@import "../../styles/variables/all-variables";
@import "../../styles/vendor/bootstrap";

.summary {
  @extend %section-layout;

  &__featured-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 300px;
    min-height: 220px;
  }

  &__featured-description {
    overflow-y: scroll;
    padding: 16px 0;

    @include media-breakpoint-down(lg) {
      &::-webkit-scrollbar {
        width: 4px;
        display: block;
        background-color: var(--color-white);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-blue);
      }

    }

  }

}