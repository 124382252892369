@import "../../styles/variables/all-variables";
@import "../../styles/abstracts/helpers";
@import "../../styles/base/all-base";

.portfolio {
  @extend %section-layout;

  &__featured-box {
      display: flex;
      gap: 32px;
  }

}