@import "../../styles/base/placeholders";
@import "../../styles/vendor/bootstrap";
.msHeader {
    @extend %flexbox-h-center;
    flex-direction: row;
    background-color: var(--color-primary);
    gap: 16px;

    @include media-breakpoint-up(lg) {
        flex-direction: column;
        height: 100vh;
        overflow-y: scroll;
        min-height: 50vh;
        gap: 32px;
    }

    @media screen and (max-height: 600px) {
        &::-webkit-scrollbar {
            width: 5px;
            display: block;
            background-color: var(--color-white);
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-shades-gray-500);
            display: inline;
            height: 10px;
        }
    }

}