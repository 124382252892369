@import "../../styles/base/placeholders";
@import "../../styles/variables/font-variables";
@import "../../styles/variables/variables";


.testimonialSlider {
  width: 100%;
  cursor: default;

  &__item-head {

    img {
      width: 15%;
    }
    p {
      span {
        color: var(--color-shades-gray-400);
      }
    }
  }

  &__item-content {
    font-family: $font-primary;
    font-size: map-get($text-sizes, sm);
    line-height: 1.3;
    letter-spacing: 2px;
    height: 80px;
    overflow-y: scroll;
    padding: 8px 0;

    &::-webkit-scrollbar {
      width: 5px;
      display: block;
      background-color: var(--color-white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-shades-gray-500);
      min-height: 5px;
    }
  }

  &__item-stars {
    display: flex;
    gap: 4px;
    svg {
      path {
        color: var(--color-warning);
      }
    }
  }


}