@import "../../styles/base/placeholders";
@import "../../styles/variables/font-variables";
@import "../../styles/variables/variables";

.portfolioGallery {
  display: block;

  &__nav-link {
    cursor: pointer;
  }

  &__items {
    position: relative;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 15vw 15vw 15vw 15vw 15vw;
    grid-auto-flow: row dense;
    grid-gap: 16px;
  }

  &__grid-item {
    position: relative;
  }

  &__0 {
    grid-row: 1 / 2;
  }
  &__1 {
    grid-row: 1 / span 2;
  }
  &__2 {
    grid-row: 1 / 2;
  }
  &__3 {
    grid-row: 2 / 4;
  }
  &__4 {
    grid-row: 3 / 4;
  }
  &__5 {
    grid-row: 2 / 4;
  }
  &__6 {
    grid-row: 4 / 5;
    grid-column: 1 / span 2;
  }

  &__9 {
    grid-column: 2 / -1;
  }

}

