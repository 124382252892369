@import "../../styles/variables/all-variables";
@import "../../styles/vendor/bootstrap";

.headerNav {
  box-shadow: 0 0 15px rgba(0,0,0,.1);
  left: 0;
  margin-top: 0;
  position: absolute;
  right: 0;
  top: 99%;
  z-index: 1000;
  background-color: rgba(0,0,0,.95);

  @include media-breakpoint-up(lg) {
    position: relative;
    top: unset;
    background-color: transparent;
  }

  &__item {

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, .2);

      @include media-breakpoint-up(lg) {
        border-bottom: none;
      }
    }

  }
  &__link {
    text-align: left;

    @include media-breakpoint-up(lg) {
      text-align: center;
    }

  }

}

.headerNavToggler {
  border: none;
  cursor: pointer;
  height: 30px;
  margin: 8px;
  padding: 10px;
  position: relative;
  transition: .5s ease-in-out;
  width: 25px;
  span {
    background: #fff;
    border-radius: 2px;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: .25s ease-in-out;
    width: 100%;

    &:first-child {
      top: 7px;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 14px;
      transform-origin: left center;

    }
    &:last-child {
      top: 21px;
      transform-origin: left center;
    }
  }
  &.toggled {
    span:first-child {
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:last-child {
      transform: rotate(-45deg);
    }
  }
}