@import "../variables/all-variables";
/* Buttons
========================================================================== */

/*
 * usage:
 * @extend %btn;
 */
%btn {
    /* stylelint-disable-line declaration-property-value-disallowed-list */
    cursor: pointer;
    box-shadow: none;
    text-align: center;
}

%btn-secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: map-get($text-sizes, lg);
    background-color: var(--color-secondary);
    border-radius: 50%;
    color: var(--color-white);
    box-shadow: 0 5px 15px rgba(0,0,0,.15);
    cursor: pointer;
    width: 44px;
    height: 44px;
    text-align: center;
    transition: all .3s ease-in-out;
    z-index: 1030;

    &:hover {
        background-color: var(--bs-primary);
        font-size: map-get($text-sizes, 2xl);
    }
}

/* Background image
========================================================================== */

/*
 * usage:
 * @extend %bg-image;
 */
%bg-image {
    background-attachment: fixed;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    transition: background-image .3s ease-in .2s;
}

%bg-absolute {
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

/* Flexbox
========================================================================== */

/*
 * usage:
 * @extend %flexbox-center;
 */
%flexbox-center {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

/*
 * usage:
 * @extend %flexbox-v-center;
 */
%flexbox-v-center {
    display: flex;
    height: 100%;
    justify-content: center;
}

/*
 * usage:
 * @extend %flexbox-h-center;
 */
%flexbox-h-center {
    align-items: center;
    display: flex;
}

%section-layout {
    overflow: hidden;
    padding: 6rem 0;
    position: relative;
}