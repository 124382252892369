/* Colors
========================================================================== */
$exp-colors: (
    'black-pearl': #111419,
    'green': #61e9b0,
    'red': #f76d6d,
    'yellow': #ffd86f,
    'black': #000000,
    'white': #ffffff,
    'gray': #808080,
    'blue': #142b66,
    'orange': #fd8204,
    'darkblue': #343a40,
    'dark-3': #232a31,
    'lightgray': #DFE0E2,
    'darkblue-2': #0d6efd,
    'lightblue-500': #009ceb,
    'lightblue-500-hover': #00A9FF,
    'lightblue-300': #dbf1fc,
    'lightblue-200': #eaf8ff,
    'green-light': #5a7778,
    'green-dark': #526869,
    'red-500': #e30613,
    'red-500-hover': #F80A18,
    'shades-gray-500': #4f6566,
    'shades-gray-400': #728182,
    'shades-gray-300': #dfdede,
    'shades-gray-200': #f3f4f4,
    'shades-blue-500': #1b1e42,
    'shades-blue-400': #b5b6c8,
    'shades-blue-300': #e6e7ed,
    'shades-blue-200': #f8f8fa,
    'shades-white-500': #C8CCCC,
    'shades-blue-light': #A0D9F6,
    'error-red-900': #ea3b3b,
    'error-red-500': #f76d6d,
    'error-red-100': #fef0f0,
    'warning-yellow-900': #f0af23,
    'warning-yellow-500': #ffd86f,
    'warning-yellow-100': #fffbf1,
    'success-green-900': #19b775,
    'success-green-500': #61e9b0,
    'success-green-100': #f3fffa,
);

/* Sizes
========================================================================== */
$text-sizes: (
    9xl: 98px,
    8xl: 88px,
    7xl: 72px,
    6xl: 60px,
    5xl: 48px,
    4xl: 36px,
    3xl: 30px,
    2xl: 24px,
    xl: 20px,
    lg: 18px,
    base: 16px,
    sm: 14px,
    xs: 12px,
    xxs: 10px,
);

$spacers: (
    0: 0,
    1: 4px,
    2: 8px,
    3: 16px,
    4: 20px,
    5: 24px,
    6: 32px,
    7: 40px,
    8: 48px,
    9: 56px,
    10: 64px,
    11: 72px,
    12: 80px,
    13: 128px,
    14: 256px,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1600px
);

$grid-gutter-width: 3rem;

