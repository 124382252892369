@import "../../../styles/variables/font-variables";
@import "../../../styles/variables/variables";
@import "../../../styles/base/placeholders";

.scrollToTop {
  @extend %btn-secondary;
  display: none;

  position: fixed;
  bottom: 32px;
  right: 32px;



  &__visible {
    display: flex;
  }
}