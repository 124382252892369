@import "../../../styles/base/placeholders";
@import "../../../styles/variables/all-variables";
@import "../../../styles/vendor/bootstrap";

.button {
  @extend %btn;
  padding: 12px 32px;
  font-weight: 500;
  font-family: $font-primary;
  letter-spacing: 4px;
  font-size: map-get($text-sizes, base);

  @include media-breakpoint-up(lg) {
    font-size: map-get($text-sizes, lg);
  }
}