@import "../../styles/variables/all-variables";
@import "../../styles/vendor/bootstrap";

.social-icons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;

  &__title {
    font-size: map-get($text-sizes, lg);
  }

  &__items {
    margin-bottom: 0;
    svg {
      font-size: 1.2em;
    }
  }
}