@import "../variables/all-variables";
/* Typography
========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--color-primary);
    font-family: $font-primary;
    font-weight: 500;
    font-style: normal;
}
